/* ------------------------------ BASE STYLES ------------------------------ */

html {
  height: 100%;
}

body {
  background-color: #f6f7f7;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

label {
  margin-bottom: 0;
}

input {
  width: 100%;
  padding: 5px;
}

.dropdown-menu {
  font-size: 1rem;
}

.rbt-input-main {
  font-size: 1rem;
}

.hidden {
  display: none;
}

.overflow-hide {
  overflow: hidden;
}

.relative {
  position: relative;
}

.flex-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
}
.flex-container-stretch {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: stretch;
  flex-wrap: wrap;
}
.flex-container .flex-1 {
  flex-grow: 1;
}
.flex-container .flex-2 {
  flex-grow: 2;
}
.flex-container .flex-3 {
  flex-grow: 3;
}
.flex-container .flex-4 {
  flex-grow: 4;
}
.flex-container .flex-5 {
  flex-grow: 5;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.form-control {
  font-size: 1rem;
}

.insys-content-bold {
  font-weight: bold;
}

.insys-content-body {
  margin-bottom: 15px;
}

/* ------------------------------ END OF BASE STYLES ------------------------------ */


/* ------------------------------ DATATABLE STYLES ------------------------------ */

.date-range-container {
  width: 200px;
}

.datatable-search-spacing {
  margin-left: 15px;
}

.datatable-search-input {
  font-size: 12px;
  padding: 6px;
  margin-right: 40px;
  margin-left: 10px;
}

.weave-react-table-column {
  text-align: center;
}

.datatable-label {
  font-weight: bold;
}

.datatable-search-container {
  margin: 20px 25px;
}

.datatable-library-container {
  margin-top: 20px;
}

/* ------------------------------ END OF DATATABLE STYLES ------------------------------ */


/* ------------------------------ BOOTSTRAP STYLES ------------------------------ */

.btn-primary,
.btn-secondary,
.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  font-size: 1rem;
}

.popover-header {
  font-size: 1rem;
}

.popover-body {
  font-size: 1rem;
}

.btn {
  margin-left: 3px;
  margin-right: 3px;
}

.btn-info {
  color: #ffffff;
  background-color: #37cff1;
  border-color: #37cff1;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #03c1e4;
  border-color: #37cff1;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #37cff1;
  border-color: #37cff1;
}

.btn-info .badge {
  color: #37cff1;
  background-color: #ffffff;
}

/* ------------------------------ END OF BOOTSTRAP STYLES ------------------------------ */


/* ------------------------------ CONTAINER STYLES ------------------------------ */

.container {
  padding: 0.5rem 1rem;
}

.main {
  background: #fafcfc;
  min-height: 600px;
  border: 1px solid #d4dbdf;
  padding: 1rem 1.5rem;
  overflow: visible;
  margin-top: 10px;
}
.main .title-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d4dbdf;
}
.main .title-group .page-title {
  font-size: 1.5rem;
}
.main .page-subtitle {
  font-size: 1.125rem;
}

/* ------------------------------ END OF CONTAINER STYLES ------------------------------ */
